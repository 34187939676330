<template>
  <div class="card card-custom gutter-b p-6" :class="`bg-${bgColor}`">
    <span class="svg-icon svg-icon-2x">
      <slot name="svg" />
    </span>

    <span class="card-title font-weight-bolder text-white mb-0 mt-4 d-block">
      <span :data-cy="cyCounterName">
        {{ count }}
      </span>

      <span v-if="count && sum"> / </span>

      <span v-if="sum">
        {{ sumWithCurrency }}
      </span>
    </span>

    <span class="font-weight-bold text-white-50 font-size-md">
      {{ label }}
    </span>
  </div>
</template>

<script>
export default {
  name: "StatsWidget",
  props: {
    count: {
      type: Number,
      default: 0,
    },

    sum: {
      type: Number,
      default: 0,
    },

    label: {
      type: String,
      default: "",
    },

    bgColor: {
      type: String,
      default: "",
    },

    currency: {
      type: String,
      default: "$",
    },

    cyCounterName: {
      type: String,
      default: "",
    },
  },

  computed: {
    sumWithCurrency() {
      return `${this.sum} ${this.currency}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.card-title {
  font-size: 1.5rem;
}

@media (max-width: 575.98px) {
  .card {
    align-items: center;

    .card-title {
      font-size: 23px;
    }
  }
}
</style>
