import gql from "graphql-tag";

export const dashboardData = gql`
  query dashboardData {
    referralCount {
      viewsCount
      totalCount
      inProgressCount
      wonCount
    }
  }
`;
