<template>
  <div data-cy="employee-dashboard-page">
    <b-row>
      <b-col sm="6" lg="3">
        <StatsWidget
          :count="referralCount.viewsCount"
          :label="$t('label.views._')"
          bg-color="primary"
        >
          <template v-slot:svg>
            <SvgIcon
              class="p-0"
              icon-folder="General"
              icon-name="Visible"
              icon-color="white"
              icon-size="sm"
            />
          </template>
        </StatsWidget>
      </b-col>

      <b-col sm="6" lg="3">
        <StatsWidget
          :count="referralCount.totalCount"
          :label="$t('label.order.list')"
          bg-color="info"
        >
          <template v-slot:svg>
            <SvgIcon
              class="p-0"
              icon-folder="General"
              icon-name="Clipboard"
              icon-color="white"
              icon-size="sm"
            />
          </template>
        </StatsWidget>
      </b-col>

      <b-col sm="6" lg="3">
        <StatsWidget
          :count="referralCount.inProgressCount"
          :label="$t('label.deal.potential._')"
          bg-color="warning"
        >
          <template v-slot:svg>
            <SvgIcon
              class="p-0"
              icon-folder="Home"
              icon-name="Clock"
              icon-color="white"
              icon-size="sm"
            />
          </template>
        </StatsWidget>
      </b-col>

      <b-col sm="6" lg="3">
        <StatsWidget
          :count="referralCount.wonCount"
          :label="$t('label.deal.success._')"
          bg-color="success"
        >
          <template v-slot:svg>
            <SvgIcon
              class="p-0"
              icon-folder="Communication"
              icon-name="Clipboard-check"
              icon-color="white"
              icon-size="sm"
            />
          </template>
        </StatsWidget>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import apolloMixin from "@/mixins/apollo.mixin";
import { dashboardData } from "./gql/queries";
import StatsWidget from "@/components/StatsWidget";
import SvgIcon from "@/components/svg/SvgIcon";

const CONTENT_WIDTH = 1200;

export default {
  name: "EmployeeDashboard",
  components: {
    StatsWidget,
    SvgIcon,
  },

  mixins: [apolloMixin],

  data: () => ({
    referralCount: {},
  }),

  async created() {
    await this.getDashboardData();
  },

  mounted() {
    this.$setBreadcrumbs();
    this.$setMaxContentWidth(CONTENT_WIDTH);
  },

  beforeDestroy() {
    this.$resetMaxContentWidth();
  },

  methods: {
    async getDashboardData() {
      const { referralCount } = await this.$get(dashboardData);

      this.referralCount = referralCount;
    },
  },
};
</script>
